/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const home = {
  index: lazy(() => import('views/publicsite/Home')), 
};
const loginadminmember = {
  index: lazy(() => import('views/default/Loginadminmember')), 
};
const aboutus = {
  index: lazy(() => import('views/publicsite/Aboutus')), 
};
const chamberbook = {
  index: lazy(() => import('views/publicsite/Chamberbook')), 
};
const Affiliated = {
  index: lazy(() => import('views/publicsite/Affiliated')), 
};
const Badminton = {
  index: lazy(() => import('views/publicsite/Badminton')), 
};
const Banquets = {
  index: lazy(() => import('views/publicsite/Banquets')), 
};
const Billiards = {
  index: lazy(() => import('views/publicsite/Billiards')), 
};
const Cards = {
  index: lazy(() => import('views/publicsite/Cards')), 
};
const Clubfacilities = {
  index: lazy(() => import('views/publicsite/Clubfacilities')), 
};
const Committemembers = {
  index: lazy(() => import('views/publicsite/Committemembers')), 
};
const Contactus = {
  index: lazy(() => import('views/publicsite/Contactus')), 
};
const Cricket = {
  index: lazy(() => import('views/publicsite/Cricket')), 
};
const Events = {
  index: lazy(() => import('views/publicsite/Events')), 
};
const Gym = {
  index: lazy(() => import('views/publicsite/Gym')), 
};
const Hockey = {
  index: lazy(() => import('views/publicsite/Hockey')), 
};
const Library = {
  index: lazy(() => import('views/publicsite/Library')), 
};
const Otherwellness = {
  index: lazy(() => import('views/publicsite/Otherwellness')), 
};
const Restaurants = {
  index: lazy(() => import('views/publicsite/Restaurants')), 
};
const Squash = {
  index: lazy(() => import('views/publicsite/Squash')), 
};
const Swimming = {
  index: lazy(() => import('views/publicsite/Swimming')), 
};
const Tennis = {
  index: lazy(() => import('views/publicsite/Tennis')), 
};
const Thechambers = {
  index: lazy(() => import('views/publicsite/Thechambers')), 
};
const Yoga = {
  index: lazy(() => import('views/publicsite/Yoga')), 
};
const Yorkerbar = {
  index: lazy(() => import('views/publicsite/Yorkerbar')), 
};
const member = {
  viewStatement: lazy(() => import('views/member/ViewStatement')),
  // changePassword: lazy(() => import('views/member/ChangePassword')),
  changePin: lazy(() => import('views/member/ChangePin')),
  foodordering: { 
    home: lazy(() => import('views/member/foodordering/home/Home')),
    cart: lazy(() => import('views/member/foodordering/cart/Cart')),
    checkout: lazy(() => import('views/member/foodordering/checkout/Checkout')),
    orders:{
      list:lazy(() => import('views/member/foodordering/orders/list/OrdersList')),
      detail: lazy(() => import('views/member/foodordering/orders/detail/OrdersDetail')),
    }
  },
  events:{
    eventsList: lazy(()=>import('views/member/events/EventsList')),
    orders:{
      list: lazy(()=>import('views/member/events/orders/list/OrdersList.js')),
      detail: lazy(() => import('views/member/events/orders/detail/OrdersDetail')),
    }
  },
  banquet:{
    Booking: lazy(()=>import('views/member/banquet/Booking')),
    List: lazy(() => import('views/member/banquet/List')),  
  },
  chamber:{
    Booking: lazy(()=>import('views/member/chamber/Booking')),
    List: lazy(() => import('views/member/chamber/List')),  
  }, 
  
  MyFeedBacks: lazy(() => import('views/member/feedbacks/list/FeedBacksList')),
  FeedBackDetail : lazy(() => import('views/member/feedbacks/detail/FeedBackDetail')),
}

const admin ={
  dashboard: lazy(() => import('views/admin/Dashboard')),
  ManageMembers: lazy(() => import('views/admin/members/ManageMembers')),
  ManageCategory: lazy(() => import('views/admin/restaurant/ManageCategory')),
  ManageSubCategory: lazy(() => import('views/admin/restaurant/ManageSubCategory')),
  ManageMenu: lazy(() => import('views/admin/restaurant/ManageMenu')),
  ManageOrder: lazy(() => import('views/admin/restaurant/orders/list/OrdersList')),
  OrderDetail: lazy(() => import('views/admin/restaurant/orders/detail/OrdersDetail')),

  ManageBanquets: lazy(() => import('views/admin/banquet/ManageBanquets')),
  ManageBanquetMenu: lazy(() => import('views/admin/banquet/ManageBanquetMenu')),
  ManageBanquetMenuItem: lazy(() => import('views/admin/banquet/ManageBanquetMenuItem')),
  ManageBanquetOrder: lazy(() => import('views/admin/banquet/orders/list/OrdersList')),
  BanquetOrderDetail: lazy(() => import('views/admin/banquet/orders/detail/OrdersDetail')),

  ManageChamberOrder: lazy(() => import('views/admin/chamber/orders/list/OrdersList')),
  ChamberOrderDetail: lazy(() => import('views/admin/chamber/orders/detail/OrdersDetail')),

  ManageEvents: lazy(() => import('views/admin/events/ManageEvents')),
  ManageEventsCategory: lazy(() => import('views/admin/events/ManageCategory')),
  ManageEventsOrder: lazy(() => import('views/admin/events/orders/list/OrdersList')),
  EventsOrderDetail: lazy(() => import('views/admin/events/orders/detail/OrdersDetail')),
  ManageFeedBacks: lazy(() => import('views/admin/feedbacks/list/FeedBacksList')),
  FeedBackDetail : lazy(() => import('views/admin/feedbacks/detail/FeedBackDetail')),
  Report: lazy(() => import('views/admin/reports/PaymentList')),
  EventReport: lazy(() => import('views/admin/reports/EventList')),
  ManageBroadcast: lazy(() => import('views/admin/messagebroadcast/managebroadcast')),
  
}


const dashboards = {
  index: lazy(() => import('views/dashboards/Dashboards')),
  default: lazy(() => import('views/dashboards/DashboardsDefault')),
  visual: lazy(() => import('views/dashboards/DashboardsVisual')),
  analytic: lazy(() => import('views/dashboards/DashboardsAnalytic')),
};
const apps = {
  index: lazy(() => import('views/apps/Apps')),
  calendar: lazy(() => import('views/apps/calendar/Calendar')),
  chat: lazy(() => import('views/apps/chat/Chat')),
  contacts: lazy(() => import('views/apps/contacts/Contacts')),
  mailbox: lazy(() => import('views/apps/mailbox/Mailbox')),
  tasks: lazy(() => import('views/apps/tasks/Tasks')),
};
const authentication = {
    index: lazy(() => import('views/pages/authentication/Authentication')),
    login: lazy(() => import('views/pages/authentication/Login')),
    home: lazy(() => import('views/pages/authentication/Home')),
    register: lazy(() => import('views/pages/authentication/Register')),
    forgotPassword: lazy(() => import('views/pages/authentication/ForgotPassword')),
    resetPassword: lazy(() => import('views/pages/authentication/ResetPassword')),
	forgotPin: lazy(() => import('views/pages/authentication/ForgotPin')),
	loginTrouble: lazy(() => import('views/pages/authentication/LoginTrouble')),
  };
const pages = {
  index: lazy(() => import('views/pages/Pages')),
  authentication: {
    index: lazy(() => import('views/pages/authentication/Authentication')),
    login: lazy(() => import('views/pages/authentication/Login')),
    home: lazy(() => import('views/pages/authentication/Home')),
    register: lazy(() => import('views/pages/authentication/Register')),
    forgotPassword: lazy(() => import('views/pages/authentication/ForgotPassword')),
    resetPassword: lazy(() => import('views/pages/authentication/ResetPassword')),
  },
  blog: {
    index: lazy(() => import('views/pages/blog/Blog')),
    home: lazy(() => import('views/pages/blog/BlogHome')),
    grid: lazy(() => import('views/pages/blog/BlogGrid')),
    list: lazy(() => import('views/pages/blog/BlogList')),
    detail: lazy(() => import('views/pages/blog/BlogDetail')),
  },
  miscellaneous: {
    index: lazy(() => import('views/pages/miscellaneous/Miscellaneous')),
    faq: lazy(() => import('views/pages/miscellaneous/Faq')),
    knowledgeBase: lazy(() => import('views/pages/miscellaneous/KnowledgeBase')),
    error: lazy(() => import('views/pages/miscellaneous/Error')),
    comingSoon: lazy(() => import('views/pages/miscellaneous/ComingSoon')),
    pricing: lazy(() => import('views/pages/miscellaneous/Pricing')),
    search: lazy(() => import('views/pages/miscellaneous/Search')),
    mailing: lazy(() => import('views/pages/miscellaneous/Mailing')),
    empty: lazy(() => import('views/pages/miscellaneous/Empty')),
  },
  portfolio: {
    index: lazy(() => import('views/pages/portfolio/Portfolio')),
    home:  lazy(() => import('views/pages/portfolio/PortfolioHome')),
    detail: lazy(() => import('views/pages/portfolio/PortfolioDetail')),
  },
  profile: {
    index: lazy(() => import('views/pages/profile/Profile')),
    standard: lazy(() => import('views/pages/profile/ProfileStandard')),
    settings: lazy(() => import('views/pages/profile/ProfileSettings')),
  },
};
const blocks = {
  index: lazy(() => import('views/blocks/Blocks')),
  cta: lazy(() => import('views/blocks/cta/Cta')),
  details: lazy(() => import('views/blocks/details/Details')),
  gallery: lazy(() => import('views/blocks/gallery/Gallery')),
  images: lazy(() => import('views/blocks/images/Images')),
  list: lazy(() => import('views/blocks/list/List')),
  stats: lazy(() => import('views/blocks/stats/Stats')),
  steps: lazy(() => import('views/blocks/steps/Steps')),
  tabularData: lazy(() => import('views/blocks/tabulardata/TabularData')),
  thumbnails: lazy(() => import('views/blocks/thumbnails/Thumbnails')),
};
const interfaces = {
  index: lazy(() => import('views/interface/Interface')),
  components: {
    index: lazy(() => import('views/interface/components/Components')),
    accordion: lazy(() => import('views/interface/components/Accordion')),
    alerts: lazy(() => import('views/interface/components/Alerts')),
    badge: lazy(() => import('views/interface/components/Badge')),
    breadcrumb: lazy(() => import('views/interface/components/Breadcrumb')),
    buttons: lazy(() => import('views/interface/components/Buttons')),
    buttonGroup: lazy(() => import('views/interface/components/ButtonGroup')),
    card: lazy(() => import('views/interface/components/Card')),
    closeButton: lazy(() => import('views/interface/components/CloseButton')),
    collapse: lazy(() => import('views/interface/components/Collapse')),
    dropdowns: lazy(() => import('views/interface/components/Dropdowns')),
    listGroup: lazy(() => import('views/interface/components/ListGroup')),
    modal: lazy(() => import('views/interface/components/Modal')),
    navs: lazy(() => import('views/interface/components/Navs')),
    offcanvas: lazy(() => import('views/interface/components/Offcanvas')),
    pagination: lazy(() => import('views/interface/components/Pagination')),
    popovers: lazy(() => import('views/interface/components/Popovers')),
    progress: lazy(() => import('views/interface/components/Progress')),
    spinners: lazy(() => import('views/interface/components/Spinners')),
    toasts: lazy(() => import('views/interface/components/Toasts')),
    tooltips: lazy(() => import('views/interface/components/Tooltips')),
  },
  forms: {
    index: lazy(() => import('views/interface/forms/Forms')),
    layouts: lazy(() => import('views/interface/forms/layouts/Layouts')),
    validation: lazy(() => import('views/interface/forms/validation/Validation')),
    wizard: lazy(() => import('views/interface/forms/wizard/Wizard')),
    inputGroup: lazy(() => import('views/interface/forms/input-group/InputGroup')),
    inputMask: lazy(() => import('views/interface/forms/input-mask/InputMask')),
    genericForms: lazy(() => import('views/interface/forms/generic-forms/GenericForms')),
    controls: {
      index: lazy(() => import('views/interface/forms/controls/Controls')),
      autocomplete: lazy(() => import('views/interface/forms/controls/autocomplete/Autocomplete')),
      checkboxRadio: lazy(() => import('views/interface/forms/controls/checkbox-radio/CheckboxRadio')),
      datePicker: lazy(() => import('views/interface/forms/controls/datepicker/Datepicker')),
      dropzone: lazy(() => import('views/interface/forms/controls/dropzone/Dropzone')),
      editor: lazy(() => import('views/interface/forms/controls/editor/Editor')),
      inputSpinner: lazy(() => import('views/interface/forms/controls/input-spinner/InputSpinner')),
      rating: lazy(() => import('views/interface/forms/controls/rating/Rating')),
      select: lazy(() => import('views/interface/forms/controls/select/Select')),
      slider: lazy(() => import('views/interface/forms/controls/slider/Slider')),
      tags: lazy(() => import('views/interface/forms/controls/tags/Tags')),
    },
  },
  plugins: {
    index: lazy(() => import('views/interface/plugins/Plugins')),
    carousel: lazy(() => import('views/interface/plugins/carousel/Carousel')),
    charts: lazy(() => import('views/interface/plugins/chart/Chart')),
    clamp: lazy(() => import('views/interface/plugins/clamp/Clamp')),
    contextMenu: lazy(() => import('views/interface/plugins/context-menu/ContextMenu')),
    datatables: {
      index: lazy(() => import('views/interface/plugins/datatables/Datatables')),
      editableRows: lazy(() => import('views/interface/plugins/datatables/EditableRows/EditableRows')),
      editableBoxed: lazy(() => import('views/interface/plugins/datatables/EditableBoxed/EditableBoxed')),
      serverSide: lazy(() => import('views/interface/plugins/datatables/ServerSide/ServerSide')),
      boxedVariations: lazy(() => import('views/interface/plugins/datatables/BoxedVariations/BoxedVariations')),
    },
    lightbox: lazy(() => import('views/interface/plugins/lightbox/Lightbox')),
    maps: lazy(() => import('views/interface/plugins/maps/Maps')),
    notification: lazy(() => import('views/interface/plugins/notification/Notification')),
    players: lazy(() => import('views/interface/plugins/player/Player')),
    progress: lazy(() => import('views/interface/plugins/progress/Progress')),
    scrollbar: lazy(() => import('views/interface/plugins/scrollbar/Scrollbar')),
    shortcuts: lazy(() => import('views/interface/plugins/shortcut/Shortcut')),
    sortable: lazy(() => import('views/interface/plugins/sortable/Sortable')),
  },
  content: {
    index: lazy(() => import('views/interface/content/Content')),
    icons: {
      index: lazy(() => import('views/interface/content/icons/Icons')),
      csLineIcons: lazy(() => import('views/interface/content/icons/CsLineIcons')),
      csInterfaceIcons: lazy(() => import('views/interface/content/icons/CsInterfaceIcons')),
      bootstrapIcons: lazy(() => import('views/interface/content/icons/BootstrapIcons')),
    },
    images: lazy(() => import('views/interface/content/Images')),
    tables: lazy(() => import('views/interface/content/Tables')),
    typography: lazy(() => import('views/interface/content/Typography')),
    menu: {
      index: lazy(() => import('views/interface/content/menu/Menu')),
      horizontal:  lazy(() => import('views/interface/content/menu/Horizontal')),
      vertical: lazy(() => import('views/interface/content/menu/Vertical')),
      verticalHidden: lazy(() => import('views/interface/content/menu/VerticalHidden')),
      verticalNoHidden: lazy(() => import('views/interface/content/menu/VerticalNoHidden')),
      mobileOnly: lazy(() => import('views/interface/content/menu/MobileOnly')),
      sidebar: lazy(() => import('views/interface/content/menu/Sidebar')),
    },
  },
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/home`, 
    },
    {
      path: `${appRoot}/home`,
      component: home.index,
      label: 'menu.home',
      icon: 'home',
    }, {
      path: `${appRoot}/loginadminmember`,
      component: loginadminmember.index,
      label: 'menu.home',
      icon: 'home',
    },
    {
      path: `${appRoot}/chamberbooking`,
      component: chamberbook.index,
      label: 'menu.chamberbook',
      icon: 'home',
    },
    {
      path: `${appRoot}/aboutus`,
      component: aboutus.index,
      label: 'menu.aboutus',
      icon: 'home',
    }, {
      path: `${appRoot}/affiliated`,
      component: Affiliated.index,
      label: 'menu.Affiliated',
      icon: 'home',
    }, {
      path: `${appRoot}/badminton`,
      component: Badminton.index,
      label: 'menu.Badminton',
      icon: 'home',
    }, {
      path: `${appRoot}/Banquets`,
      component: Banquets.index,
      label: 'menu.Banquets',
      icon: 'home',
    }, {
      path: `${appRoot}/billiards`,
      component: Billiards.index,
      label: 'menu.Billiards',
      icon: 'home',
    }, {
      path: `${appRoot}/Cards`,
      component: Cards.index,
      label: 'menu.Cards',
      icon: 'home',
    }, {
      path: `${appRoot}/Clubfacilities`,
      component: Clubfacilities.index,
      label: 'menu.Clubfacilities',
      icon: 'home',
    }, {
      path: `${appRoot}/Committemembers`,
      component: Committemembers.index,
      label: 'menu.Committemembers',
      icon: 'home',
    }, {
      path: `${appRoot}/Contactus`,
      component: Contactus.index,
      label: 'menu.Contactus',
      icon: 'home',
    }, {
      path: `${appRoot}/Cricket`,
      component: Cricket.index,
      label: 'menu.Cricket',
      icon: 'home',
    }, {
      path: `${appRoot}/Events`,
      component: Events.index,
      label: 'menu.Events',
      icon: 'home',
    }, {
      path: `${appRoot}/Gym`,
      component: Gym.index,
      label: 'menu.Gym',
      icon: 'home',
    }, {
      path: `${appRoot}/Hockey`,
      component: Hockey.index,
      label: 'menu.Hockey',
      icon: 'home',
    }, {
      path: `${appRoot}/Library`,
      component: Library.index,
      label: 'menu.Library',
      icon: 'home',
    }, {
      path: `${appRoot}/Otherwellness`,
      component: Otherwellness.index,
      label: 'menu.Otherwellness',
      icon: 'home',
    }, {
      path: `${appRoot}/Restaurants`,
      component: Restaurants.index,
      label: 'menu.Restaurants',
      icon: 'home',
    }, {
      path: `${appRoot}/Squash`,
      component: Squash.index,
      label: 'menu.Squash',
      icon: 'home',
    }, {
      path: `${appRoot}/Swimming`,
      component: Swimming.index,
      label: 'menu.Swimming',
      icon: 'home',
    }, {
      path: `${appRoot}/Tennis`,
      component: Tennis.index,
      label: 'menu.Tennis',
      icon: 'home',
    }, {
      path: `${appRoot}/Thechambers`,
      component: Thechambers.index,
      label: 'menu.Thechambers',
      icon: 'home',
    }, {
      path: `${appRoot}/Yoga`,
      component: Yoga.index,
      label: 'menu.Yoga',
      icon: 'home',
    }, {
      path: `${appRoot}/Yorkerbar`,
      component: Yorkerbar.index,
      label: 'menu.Yorkerbar',
      icon: 'home',
    },
	 {
      path: `${appRoot}/member`,
      // component: dashboards.index,
      label: 'menu.member',
      icon: 'home',
       subs: [
        { 
          path: '/viewStatement', label: 'menu.viewStatement', component: member.viewStatement, noLayout: true },
          {
            path: '/foodordering',
            label: 'menu.member',
           //  component: pages.authentication.index,
            subs: [
              { path: '/home', label: 'menu.login', component:member.foodordering.home, noLayout: true },
              { path: '/filters', label: 'menu.home', component: member.foodordering.filters, noLayout: true },
              { path: '/categories', label: 'menu.register', component: member.foodordering.categories, noLayout: true },
              { path: '/detail', label: 'menu.reset-password', component: member.foodordering.detail, noLayout: true },
              { path: '/cart', label: 'menu.forgot-password', component: member.foodordering.cart, noLayout: true },
              { path: '/checkout', label: 'menu.reset-password', component: member.foodordering.checkout, noLayout: true },
              { path: '/invoice', label: 'menu.forgot-password', component: member.foodordering.invoice, noLayout: true },
              { path: '/orders/list', label: 'menu.forgot-password', component: member.foodordering.orders.list, noLayout: true },
              { path: '/orders/detail/:id', label: 'menu.forgot-password', component: member.foodordering.orders.detail, noLayout: true },
              
            ],
          },
          {
          path: '/events',
          label: 'menu.member',
          subs:[
            { path: '/eventsList', component: member.events.eventsList, label: 'menu.Events', noLayout: true},
            { path: '/orders/list', component: member.events.orders.list, label: 'menu.Events', noLayout: true},
            { path: '/orders/detail/:id', label: 'menu.Events', component: member.events.orders.detail, noLayout: true },
          ]
          },
          {
            path: '/chamber',
            label: 'menu.member',
            subs:[
              { path: '/booking', component: member.chamber.Booking, label: 'Book Chambers', noLayout: true},
              { path: '/myBooking', label: 'My Chamber Bookings', component: member.chamber.List, noLayout: true },
            ]
          },
          {
            path: '/banquet',
            label: 'menu.member',
            subs:[
              { path: '/booking', component: member.banquet.Booking, label: 'Book Banquet Hall', noLayout: true},
              { path: '/myBooking', label: 'My Banquet Bookings', component: member.banquet.List, noLayout: true },
            ]
          },
          { path: '/feedbacks/FeedBacksList', label: 'menu.dashboard', component: member.MyFeedBacks, noLayout: true },
          { path: '/feedbacks/FeedBackDetail/:id', label: 'menu.dashboard', component: member.FeedBackDetail, noLayout: true },
        
	  {
	   path:'/settings',
           label:'menu.settings',
	   subs:[
			{ 
        // path:'/changePassword', label: 'menu.viewStatement', component: member.changePassword, noLayout: true 
        path:'/changePin', label: 'menu.viewStatement', component: member.changePin, noLayout: true}, 
		]
	  },

      ],
    },
    {
      path: `${appRoot}/admin`,
      // component: dashboards.index,
      label: 'menu.admin',
      icon: 'home',
       subs: [
        { path: '/dashboard', label: 'menu.dashboard', component: admin.dashboard, noLayout: true },
        { path: '/member/manageMembers', label: 'menu.dashboard', component: admin.ManageMembers, noLayout: true },
        { path: '/restaurant/ManageCategory', label: 'menu.dashboard', component: admin.ManageCategory, noLayout: true },
        { path: '/restaurant/ManageSubCategory/:id', label: 'menu.dashboard', component: admin.ManageSubCategory, noLayout: true },
        { path: '/restaurant/ManageMenu', label: 'menu.dashboard', component: admin.ManageMenu, noLayout: true },
        { path: '/restaurant/ManageOrder', label: 'menu.dashboard', component: admin.ManageOrder, noLayout: true },
        { path: '/restaurant/OrderDetail/:id', label: 'menu.dashboard', component: admin.OrderDetail, noLayout: true },

        { path: '/banquet/ManageBanquets', label: 'menu.dashboard', component: admin.ManageBanquets, noLayout: true },
        { path: '/banquet/ManageBanquetMenu', label: 'menu.dashboard', component: admin.ManageBanquetMenu, noLayout: true },
        { path: '/banquet/ManageBanquetMenuItem', label: 'menu.dashboard', component: admin.ManageBanquetMenuItem, noLayout: true },
        { path: '/banquet/ManageBanquetOrder', label: 'menu.dashboard', component: admin.ManageBanquetOrder, noLayout: true },
        { path: '/banquet/OrderDetail/:id', label: 'menu.dashboard', component: admin.BanquetOrderDetail, noLayout: true },

        { path: '/chamber/ManageChamberOrder', label: 'menu.dashboard', component: admin.ManageChamberOrder, noLayout: true },
        { path: '/chamber/OrderDetail/:id', label: 'menu.dashboard', component: admin.ChamberOrderDetail, noLayout: true },

        { path: '/events/manageEvents', label: 'menu.dashboard', component: admin.ManageEvents, noLayout: true },
        { path: '/events/ManageCategory', label: 'menu.dashboard', component: admin.ManageEventsCategory, noLayout: true },
        { path: '/events/ManageOrder', label: 'menu.dashboard', component: admin.ManageEventsOrder, noLayout: true },
        { path: '/events/OrderDetail/:id', label: 'menu.dashboard', component: admin.EventsOrderDetail, noLayout: true },
        { path: '/feedbacks/FeedBacksList', label: 'menu.dashboard', component: admin.ManageFeedBacks, noLayout: true },
        { path: '/feedbacks/FeedBackDetail/:id', label: 'menu.dashboard', component: admin.FeedBackDetail, noLayout: true },
        { path: '/report/payment', label: 'menu.dashboard', component: admin.Report, noLayout: true },
        { path: '/report/event', label: 'menu.dashboard', component: admin.EventReport, noLayout: true },
        { path: '/message/managebroadcast', label: 'menu.dashboard', component: admin.ManageBroadcast, noLayout: true },
        
      ],
    },
    {
      path: `${appRoot}/dashboards`,
      component: dashboards.index,
      label: 'menu.dashboards',
      icon: 'home',
       subs: [
        { path: '/default', label: 'menu.default', component: dashboards.default },
        { path: '/visual', label: 'menu.visual', component: dashboards.visual },
        { path: '/analytic', label: 'menu.analytic', component: dashboards.analytic },
      ],
    },
    {
      path: `${appRoot}/apps`,
      label: 'menu.apps',
      icon: 'screen',
      component: apps.index,
      subs: [
        { path: '/calendar', label: 'menu.calendar', component: apps.calendar },
        { path: '/chat', label: 'menu.chat', component: apps.chat },
        { path: '/contacts', label: 'menu.contacts', component: apps.contacts },
        { path: '/mailbox', label: 'menu.mailbox', component: apps.mailbox },
        { path: '/tasks', label: 'menu.tasks', component: apps.tasks },
      ],
    },
	{
      path: `${appRoot}/authentication`,
      label: 'menu.authentication',
      icon: 'notebook-1',
      component: authentication.index,
      subs: [
            { path: '/login', label: 'menu.login', component: pages.authentication.login, noLayout: true },
            { path: '/home', label: 'menu.home', component: pages.authentication.home, noLayout: true },
            { path: '/register', label: 'menu.register', component: pages.authentication.register, noLayout: true },
            { path: '/forgot-password', label: 'menu.forgot-password', component: pages.authentication.forgotPassword, noLayout: true },
            { path: '/reset-password', label: 'menu.reset-password', component: pages.authentication.resetPassword, noLayout: true },
			{ path: '/forgot-username', label: 'menu.forgot-username', component: pages.authentication.forgotUsername, noLayout: true },
			{ path: '/forgot-pin', label: 'menu.forgot-pin', component: pages.authentication.forgotPin, noLayout: true },
          ],
    },
    {
      path: `${appRoot}/pages`,
      label: 'menu.pages',
      icon: 'notebook-1',
      component: pages.index,
      subs: [
        {
          path: '/authentication',
          label: 'menu.authentication',
          component: pages.authentication.index,
          subs: [
            { path: '/login', label: 'menu.login', component: pages.authentication.login, noLayout: true },
            { path: '/home', label: 'menu.home', component: pages.authentication.home, noLayout: true },
            { path: '/register', label: 'menu.register', component: pages.authentication.register, noLayout: true },
            { path: '/reset-password', label: 'menu.reset-password', component: pages.authentication.resetPassword, noLayout: true },
            { path: '/forgot-password', label: 'menu.forgot-password', component: pages.authentication.forgotPassword, noLayout: true },
          ],
        },
        {
          path: '/blog',
          label: 'menu.blog',
          component: pages.blog.index,
          subs: [
            { path: '/home', label: 'menu.home', component: pages.blog.home },
            { path: '/grid', label: 'menu.grid', component: pages.blog.grid },
            { path: '/list', label: 'menu.list', component: pages.blog.list },
            { path: '/detail', label: 'menu.detail', component: pages.blog.detail },
          ],
        },
        {
          path: '/miscellaneous',
          label: 'menu.miscellaneous',
          component: pages.miscellaneous.index,
          subs: [
            { path: '/faq', label: 'menu.faq', component: pages.miscellaneous.faq },
            { path: '/knowledge-base', label: 'menu.knowledge-base', component: pages.miscellaneous.knowledgeBase },
            { path: '/error', label: 'menu.error', component: pages.miscellaneous.error, noLayout: true },
            { path: '/coming-soon', label: 'menu.coming-soon', component: pages.miscellaneous.comingSoon, noLayout: true },
            { path: '/pricing', label: 'menu.pricing', component: pages.miscellaneous.pricing },
            { path: '/search', label: 'menu.search', component: pages.miscellaneous.search },
            { path: '/mailing', label: 'menu.mailing', component: pages.miscellaneous.mailing },
            { path: '/empty', label: 'menu.empty', component: pages.miscellaneous.empty },
          ],
        },
        {
          path: '/portfolio',
          label: 'menu.portfolio',
          component: pages.portfolio.index,
          subs: [
            { path: '/home', label: 'menu.home', component: pages.portfolio.home },
            { path: '/detail', label: 'menu.detail', component: pages.portfolio.detail },
          ],
        },
        {
          path: '/profile',
          label: 'menu.profile',
          component: pages.profile.index,
          subs: [
            { path: '/standard', label: 'menu.standard', component: pages.profile.standard },
            { path: '/settings', label: 'menu.settings', component: pages.profile.settings },
          ],
        },
      ],
    },
    {
      path: `${appRoot}/blocks`,
      label: 'menu.blocks',
      icon: 'grid-5',
      component: blocks.index,
      subs: [
        { path: '/cta', label: 'menu.cta', component: blocks.cta },
        { path: '/details', label: 'menu.details', component: blocks.details },
        { path: '/gallery', label: 'menu.gallery', component: blocks.gallery },
        { path: '/images', label: 'menu.images', component: blocks.images },
        { path: '/list', label: 'menu.list', component: blocks.list },
        { path: '/stats', label: 'menu.stats', component: blocks.stats },
        { path: '/steps', label: 'menu.steps', component: blocks.steps },
        { path: '/tabular-data', label: 'menu.tabular-data', component: blocks.tabularData },
        { path: '/thumbnails', label: 'menu.thumbnails', component: blocks.thumbnails },
      ],
    },
    {
      path: `${appRoot}/interface`,
      label: 'menu.interface',
      icon: 'pocket-knife',
      component: interfaces.index,
      roles: [USER_ROLE.Admin, USER_ROLE.Editor],
      mega: true,
      subs: [
        {
          path: '/components',
          label: 'menu.components',
          component: interfaces.components.index,
          subs: [
            { path: '/accordion', label: 'menu.accordion', component: interfaces.components.accordion },
            { path: '/alerts', label: 'menu.alerts', component: interfaces.components.alerts },
            { path: '/badge', label: 'menu.badge', component: interfaces.components.badge },
            { path: '/breadcrumb', label: 'menu.breadcrumb', component: interfaces.components.breadcrumb },
            { path: '/buttons', label: 'menu.buttons', component: interfaces.components.buttons },
            { path: '/button-group', label: 'menu.button-group', component: interfaces.components.buttonGroup },
            { path: '/card', label: 'menu.card', component: interfaces.components.card },
            { path: '/close-button', label: 'menu.close-button', component: interfaces.components.closeButton },
            { path: '/collapse', label: 'menu.collapse', component: interfaces.components.collapse },
            { path: '/dropdowns', label: 'menu.dropdowns', component: interfaces.components.dropdowns },
            { path: '/list-group', label: 'menu.list-group', component: interfaces.components.listGroup },
            { path: '/modal', label: 'menu.modal', component: interfaces.components.modal },
            { path: '/navs', label: 'menu.navs', component: interfaces.components.navs },
            { path: '/offcanvas', label: 'menu.offcanvas', component: interfaces.components.offcanvas },
            { path: '/pagination', label: 'menu.pagination', component: interfaces.components.pagination },
            { path: '/popovers', label: 'menu.popovers', component: interfaces.components.popovers },
            { path: '/progress', label: 'menu.progress', component: interfaces.components.progress },
            { path: '/spinners', label: 'menu.spinners', component: interfaces.components.spinners },
            { path: '/toasts', label: 'menu.toasts', component: interfaces.components.toasts },
            { path: '/tooltips', label: 'menu.tooltips', component: interfaces.components.tooltips },
          ],
        },
        {
          path: '/forms',
          label: 'menu.forms',
          component: interfaces.forms.index,
          subs: [
            { path: '/layouts', label: 'menu.layouts', component: interfaces.forms.layouts },
            { path: '/validation', label: 'menu.validation', component: interfaces.forms.validation },
            { path: '/wizard', label: 'menu.wizard', component: interfaces.forms.wizard },
            { path: '/input-group', label: 'menu.input-group', component: interfaces.forms.inputGroup },
            { path: '/input-mask', label: 'menu.input-mask', component: interfaces.forms.inputMask },
            { path: '/generic-forms', label: 'menu.generic-forms', component: interfaces.forms.genericForms },
            {
              path: '/controls',
              label: 'menu.controls',
              component: interfaces.forms.controls.index,
              subs: [
                {
                  path: '/autocomplete',
                  label: 'menu.autocomplete',
                  component: interfaces.forms.controls.autocomplete,
                },
                {
                  path: '/checkbox-radio',
                  label: 'menu.checkbox-radio',
                  component: interfaces.forms.controls.checkboxRadio,
                },
                { path: '/date-picker', label: 'menu.date-picker', component: interfaces.forms.controls.datePicker },
                { path: '/dropzone', label: 'menu.dropzone', component: interfaces.forms.controls.dropzone },
                { path: '/editor', label: 'menu.editor', component: interfaces.forms.controls.editor },
                {
                  path: '/input-spinner',
                  label: 'menu.input-spinner',
                  component: interfaces.forms.controls.inputSpinner,
                },
                { path: '/rating', label: 'menu.rating', component: interfaces.forms.controls.rating },
                { path: '/select', label: 'menu.select', component: interfaces.forms.controls.select },
                { path: '/slider', label: 'menu.slider', component: interfaces.forms.controls.slider },
                { path: '/tags', label: 'menu.tags', component: interfaces.forms.controls.tags },
              ],
            },
          ],
        },
        {
          path: '/plugins',
          label: 'menu.plugins',
          component: interfaces.plugins.index,
          subs: [
            { path: '/carousel', label: 'menu.carousel', component: interfaces.plugins.carousel },
            { path: '/charts', label: 'menu.charts', component: interfaces.plugins.charts },
            { path: '/clamp', label: 'menu.clamp', component: interfaces.plugins.clamp },
            { path: '/context-menu', label: 'menu.context-menu', component: interfaces.plugins.contextMenu },
            {
              path: '/datatables',
              label: 'menu.datatables',
              component: interfaces.plugins.datatables.index,
              subs: [
                {
                  path: '/editable-rows',
                  label: 'menu.editable-rows',
                  component: interfaces.plugins.datatables.editableRows,
                },
                {
                  path: '/editable-boxed',
                  label: 'menu.editable-boxed',
                  component: interfaces.plugins.datatables.editableBoxed,
                },
                {
                  path: '/server-side',
                  label: 'menu.server-side',
                  component: interfaces.plugins.datatables.serverSide,
                },
                {
                  path: '/boxed-variations',
                  label: 'menu.boxed-variations',
                  component: interfaces.plugins.datatables.boxedVariations,
                },
              ],
            },
            { path: '/lightbox', label: 'menu.lightbox', component: interfaces.plugins.lightbox },
            { path: '/maps', label: 'menu.maps', component: interfaces.plugins.maps },
            { path: '/notification', label: 'menu.notification', component: interfaces.plugins.notification },
            { path: '/players', label: 'menu.players', component: interfaces.plugins.players },
            { path: '/progress', label: 'menu.progress', component: interfaces.plugins.progress },
            { path: '/scrollbar', label: 'menu.scrollbar', component: interfaces.plugins.scrollbar },
            { path: '/shortcuts', label: 'menu.shortcuts', component: interfaces.plugins.shortcuts },
            { path: '/sortable', label: 'menu.sortable', component: interfaces.plugins.sortable },
          ],
        },
        {
          path: '/content',
          label: 'menu.content',
          component: interfaces.content.index,
          roles: [USER_ROLE.Admin, USER_ROLE.Editor],
          subs: [
            {
              path: '/icons',
              label: 'menu.icons',
              component: interfaces.content.icons.index,
              subs: [
                {
                  path: '/cs-line-icons',
                  label: 'menu.cs-line-icons',
                  component: interfaces.content.icons.csLineIcons,
                },
                {
                  path: '/cs-interface-icons',
                  label: 'menu.cs-interface-icons',
                  component: interfaces.content.icons.csInterfaceIcons,
                },
                {
                  path: '/bootstrap-icons',
                  label: 'menu.bootstrap-icons',
                  component: interfaces.content.icons.bootstrapIcons,
                },
              ],
            },
            { path: '/images', label: 'menu.images', component: interfaces.content.images },
            { path: '/tables', label: 'menu.tables', component: interfaces.content.tables },
            { path: '/typography', label: 'menu.typography', component: interfaces.content.typography },
            {
              path: '/menu',
              label: 'menu.menu',
              component: interfaces.content.menu.index,
              subs: [
                { path: '/horizontal', label: 'menu.horizontal', component: interfaces.content.menu.horizontal },
                { path: '/vertical', label: 'menu.vertical', component: interfaces.content.menu.vertical },
                {
                  path: '/vertical-hidden',
                  label: 'menu.vertical-hidden',
                  component: interfaces.content.menu.verticalHidden,
                },
                {
                  path: '/vertical-no-hidden',
                  label: 'menu.vertical-no-hidden',
                  component: interfaces.content.menu.verticalNoHidden,
                },
                { path: '/mobile-only', label: 'menu.mobile-only', component: interfaces.content.menu.mobileOnly },
                { path: '/sidebar', label: 'menu.sidebar', component: interfaces.content.menu.sidebar },
              ],
            },
          ],
        },
      ],
    },
  ],
  adminMenuItems: [
    {
      path: `${appRoot}/admin/dashboard`,
      component: admin.dashboard,
      label: 'menu.dashboard',
      icon: 'home',
      
    },
    {
      path: `${appRoot}/admin/member`,
      // component: admin.dashboard,
      label: 'menu.member',
      icon: 'user',
      subs: [
        { path: '/manageMembers', label: 'menu.ManageMember', component: admin.ManageMembers, noLayout: true },

      ],
    },
    {
      path: `${appRoot}/admin/restaurant`,
      // component: admin.dashboard,
      label: 'menu.restaurantMaster',
      icon: 'main-course',
      subs: [
        { path: '/ManageCategory', label: 'menu.ManageCategory', component: admin.ManageCategory, noLayout: true },
        { path: '/ManageMenu', label: 'menu.ManageFoodItem', component: admin.ManageMenu, noLayout: true },
        { path: '/ManageOrder', label: 'menu.ManageFoodOrder', component: admin.ManageOrder, noLayout: true },
        
        
       

      ],
    },
    {
      path: `${appRoot}/admin/banquet`,
      component: admin.dashboard,
      label: 'menu.Banquet',
      icon: 'tea',
      subs: [
        { path: '/ManageBanquets', label: 'menu.ManageBanquets', component: admin.ManageBanquets, noLayout: true },
        { path: '/ManageBanquetMenu', label: 'menu.ManageBanquetMenu', component: admin.ManageBanquetMenu, noLayout: true },
        { path: '/ManageBanquetMenuItem', label: 'menu.ManageBanquetMenuItem', component: admin.ManageBanquetMenuItem, noLayout: true },
        { path: '/ManageBanquetOrder', label: 'menu.ManageBanquetOrder', component: admin.ManageBanquetOrder, noLayout: true },
        /* { path: '/BarManageCategory', label: 'menu.BarManageCategory', component: admin.BarManageCategory, noLayout: true },
        { path: '/BarManageMenu', label: 'menu.BarManageFoodItem', component: admin.BarManageMenu, noLayout: true },
        { path: '/BarManageOrder', label: 'menu.BarManageFoodOrder', component: admin.BarManageOrder, noLayout: true },
      */
      ],
    },
    {
      path: `${appRoot}/admin/chamber`,
      component: admin.dashboard,
      label: 'menu.Chamber',
      icon: 'building-large',
      subs: [
        { path: '/ManageChamberOrder', label: 'menu.ManageOrders', component: admin.ManageChamberOrder, noLayout: true },
        // { path: '/login', label: 'menu.reservationRequest', component: admin.ManageCategory, noLayout: true },
      ],
    },
    {
      path: `${appRoot}/admin/events`,
      component: admin.dashboard,
      label: 'menu.events',
      icon: 'board-3',
      subs: [
        { path: '/manageEvents', label: 'menu.ManageEvents', component: admin.ManageEvents, noLayout: true },
        { path: '/ManageCategory', label: 'menu.ManageCategory', component: admin.ManageEventsCategory, noLayout: true },
        { path: '/ManageOrder', label: 'menu.ManageEventsOrder', component: admin.ManageEventsOrder, noLayout: true },
        
      ],
    },
    {
      path: `${appRoot}/admin/feedbacks`,
      component: admin.dashboard,
      label: 'menu.feedbacks',
      icon: 'message',
      subs: [
        { path: '/FeedBacksList', label: 'menu.ManageFeedBacks', component: admin.ManageFeedBacks, noLayout: true },
       
        
      ],
    },
    {
      path: `${appRoot}/admin/report`,
      component: admin.dashboard,
      label: 'menu.report',
      icon: 'notebook-1',
      subs: [
        { path: '/payment', label: 'menu.paymentreport', component: admin.Report, noLayout: true },
        { path: '/event', label: 'menu.eventreport', component: admin.EventReport, noLayout: true },

      ],
    },
    {
      path: `${appRoot}/admin/message`,
      // component: admin.dashboard,
      label: 'menu.broadcast',
      icon: 'antenna',
      subs: [
        { path: '/managebroadcast', label: 'menu.ManageBroadcast', component: admin.ManageBroadcast, noLayout: true },

      ],
    }
  ],
  sidebarItems: [
    { path: '#connections', label: 'menu.connections', icon: 'diagram-1', hideInRoute: true },
    { path: '#bookmarks', label: 'menu.bookmarks', icon: 'bookmark', hideInRoute: true },
    { path: '#requests', label: 'menu.requests', icon: 'diagram-2', hideInRoute: true },
    {
      path: '#account',
      label: 'menu.account',
      icon: 'user',
      hideInRoute: true,
      subs: [
        { path: '/settings', label: 'menu.settings', icon: 'gear', hideInRoute: true },
        { path: '/password', label: 'menu.password', icon: 'lock-off', hideInRoute: true },
        { path: '/devices', label: 'menu.devices', icon: 'mobile', hideInRoute: true },
      ],
    },
    {
      path: '#notifications',
      label: 'menu.notifications',
      icon: 'notification',
      hideInRoute: true,
      subs: [
        { path: '/email', label: 'menu.email', icon: 'email', hideInRoute: true },
        { path: '/sms', label: 'menu.sms', icon: 'message', hideInRoute: true },
      ],
    },
    {
      path: '#downloads',
      label: 'menu.downloads',
      icon: 'download',
      hideInRoute: true,
      subs: [
        { path: '/documents', label: 'menu.documents', icon: 'file-text', hideInRoute: true },
        { path: '/images', label: 'menu.images', icon: 'file-image', hideInRoute: true },
        { path: '/videos', label: 'menu.videos', icon: 'file-video', hideInRoute: true },
      ],
    },
  ],
  memberMainMenuItems: [
    { path: '/member/viewStatement', label: 'Member Dashboard', icon: 'diagram-1', hideInRoute: true },
    { path: '/member/foodordering', label: 'F&B', icon: 'diagram-2', hideInRoute: true,
  
    subs: [
      { path: '/home', label: 'F&B Food Items', icon: 'cupcake', hideInRoute: true,},
      {path: '/orders/list', label: 'F&B Orders', icon: 'note', hideInRoute: true,}
    ]
    },
    // { path: '#', label: 'The Chambers - Coming soon', icon: 'layout-1', hideInRoute: true },
    { path: '/member/chamber', label: 'The Chambers', icon: 'layout-1', hideInRoute: true, 
    subs:[
      { path: '/booking' , label: 'Book Chambers', icon: 'layout-1', hideInRoute: true },
      { path: '/myBooking' , label: 'My Chamber Bookings', icon: 'layout-1', hideInRoute: true },
    ],

  },
    // { path: '#', label: 'Banquets - Coming soon', icon: 'crown', hideInRoute: true },
    { path: '/member/banquet', label: 'Banquets', icon: 'crown', hideInRoute: true,
    subs:[
      { path: '/booking' , label: 'Book Banquet Hall', icon: 'layout-1', hideInRoute: true },
      { path: '/myBooking' , label: 'My Banquet Bookings', icon: 'layout-1', hideInRoute: true },
    ],
  },
     {
      path: '/member/events',
      label: 'Events',
      icon: 'birthday',
      hideInRoute: true,
       subs: [
        { path: '/eventsList', label: 'Events List',  icon: 'crown', hideInRoute: true },
        {  path: '/orders/list', label: 'My Events Booking', icon: 'birthday', hideInRoute: true   },
        
      ], 
    }, 
    /* {
      path: '#',
      label: 'Events - Coming soon', 
      icon: 'birthday',
      hideInRoute: true,
    },*/
    // {
    //   path: '#',
    //   label: 'Notice Board - Coming soon',
    //   icon: 'notification',
    //   hideInRoute: true,
      
    // },
    // {
    //   path: '#',
    //   label: 'Clubman - Coming soon',
    //   icon: 'book-open',
    //   hideInRoute: true,
     
    // },
    // {
    //   path: '#',
    //   label: 'Feedback - Coming soon',
    //   icon: 'email',
    //   hideInRoute: true,
     
    // },
    {
      path: `${appRoot}/member/feedbacks`,
      component: admin.dashboard,
      label: 'menu.feedbacks',
      icon: 'message',
      subs: [
        { path: '/FeedBacksList', label: 'menu.myFeedBacks', component: member.MyFeedBacks, noLayout: true },
       
        
      ],
    },
    /* {
      path: '#',
      label: 'Facilities Timings',
      icon: 'clock',
      hideInRoute: true,
     
    }, */
    {
     path: '/member/settings',
      label: 'menu.settings',
      icon: 'gear',
      hideInRoute: true,
       subs: [
        { 
          // path: '/changePassword', label: 'Change Password',  icon: 'settings-2', hideInRoute: true
          path: '/changePin', label: 'Change Pin',  icon: 'settings-2', hideInRoute: true  }, 
        
        
      ], 

     
    }, 
   
  ],
};
export default routesAndMenuItems;
